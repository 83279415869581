import React, { useState } from "react";
import {
  Stepper,
  Paper,
  Step,
  StepLabel,
  Button,
  makeStyles,
  Container,
  Typography,
  Divider,
  FormLabel,
  FormControl,
  IconButton,
  OutlinedInput,
  TextField,
  Grid,
  Radio,
  Switch,
  RadioGroup,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardContent,
  Card,
} from "@material-ui/core";
import PropTypes from "prop-types";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PatientDataService from "../services/PatientDataService";
import ClinicDataService from "../services/ClinicDataService";
import StepConnector from "@material-ui/core/StepConnector";
import { withStyles } from "@material-ui/core/styles";
import { useAppContext } from "../services/authContext";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function getSteps() {
  return [
    "Medical Card Number",
    "General Info",
    "Registration",
    "Additional Info",
  ];
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "40%",
  },
  button: {
    margin: theme.spacing(2),
  },
  tableCellTop: {
    verticalAlign: "top",
  },
  iconContainer: {
    transform: "scale(1.5)",
  },
  step_label_root: {
    fontSize: "18px",
    marginLeft: "15px",
  },
  tabTitle: {
    padding: 30,
    fontWeight: 700,
    color: "#1A568E",
  },
  inputTitle: {
    padding: 10,
    fontWeight: 700,
    color: "#1A568E",
  },
  checkBoxLabel: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  textField: {
    height: 40,
    backgroundColor: "#EFF1F7",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={8}
          defaultValue="Default Value"
          variant="outlined"
          fullWidth
        />
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function NewPatient(props) {
  const classes = useStyles();
  const steps = getSteps();
  const { displayMessage, displayAlert, clinicId } = useAppContext();
  const [activeStep, setActiveStep] = useState(0);
  const [newPatient, setNewPatient] = useState({});
  const [hasMCN, setHasMCN] = useState("no");
  const [mcn, setMcn] = useState("");
  const [candidateList, setCandidateList] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(0);
  const [selectedDoctorId, setSelectedDoctorId] = useState(0);
  const [errors, setErrors] = useState({});
  const [dateExp, setDateExp] = useState(null);
  const [dateBd, setDateBd] = useState(null);
  const [patientGender, setPatientGender] = useState("");
  const [photo, setPhoto] = useState("");

  const changePatientInfo = (keyWord) => (event) => {
    setNewPatient({ ...newPatient, [keyWord]: event.target.value });
  };

  const changeHasMCN = (event) => {
    setHasMCN(event.target.value);
    if (hasMCN === "yes") {
      setMcn("");
      setNewPatient({ ...newPatient, mediCardNumber: "" });
    }
  };

  const changeMcn = (event) => {
    setMcn(event.target.value);
  };

  const changePhoneType = (type) => (event) => {
    setNewPatient({ ...newPatient, contactPhoneType: type });
  };

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      fontWeight: "700",
      "& $line": {
        backgroundColor: "#3553B2",
      },
    },
    completed: {
      "& $line": {
        backgroundColor: "#3553B2",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
      width: "80%",
      margin: "0 auto",
    },
  })(StepConnector);

  function selectPatientId(event) {
    setSelectedPatientId(parseInt(event.target.value));
  }

  function selectDoctorId(event) {
    setSelectedDoctorId(parseInt(event.target.value));
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function isEmpty(item) {
    return !item || item.length === 0;
  }

  function validateMCN() {
    if (isEmpty(mcn)) {
      setErrors({ ...errors, mcn: "Medical card number cannot be empty" });
      return false;
    }
    return true;
  }

  function validatePatient() {
    let tempErrors = {};
    if (isEmpty(newPatient.mediCardNumber) && hasMCN === "yes") {
      tempErrors = {
        ...tempErrors,
        mediCardNumber: "Medical card cannot be empty",
      };
    }
    // if (isEmpty(newPatient.mediExp) && hasMCN === "yes") {
    //   tempErrors = {
    //     ...tempErrors,
    //     mediExp: "Medical card expiration cannot be empty",
    //   };
    // }
    if (isEmpty(newPatient.firstName) && isEmpty(newPatient.lastName)) {
      tempErrors = {
        ...tempErrors,
        firstName: "First name cannot be empty if no last name",
        lastName: "Last name cannot be empty if no first name",
      };
    }
    if (isEmpty(newPatient.contactPhone)) {
      tempErrors = {
        ...tempErrors,
        contactPhone: "Phone number cannot be empty",
      };
    }
    if (isEmpty(newPatient.dateOfBirth) && hasMCN === "yes") {
      tempErrors = {
        ...tempErrors,
        dateOfBirth: "Date of birth cannot be empty",
      };
    }
    // if (isEmpty(newPatient.email)) {
    //   tempErrors = {
    //     ...tempErrors,
    //     email: "Email cannot be empty",
    //   };
    // }
    if (isEmpty(newPatient.postcode) && hasMCN === "yes") {
      tempErrors = {
        ...tempErrors,
        postcode: "PostalCode cannot be empty",
      };
    }
    if (isEmpty(newPatient.streetNumber) && hasMCN === "yes") {
      tempErrors = {
        ...tempErrors,
        streetNumber: "Street number cannot be empty",
      };
    }
    if (isEmpty(newPatient.streetName) && hasMCN === "yes") {
      tempErrors = {
        ...tempErrors,
        streetName: "Street name cannot be empty",
      };
    }
    // if (isEmpty(newPatient.city)) {
    //   tempErrors = {
    //     ...tempErrors,
    //     city: "City cannot be empty",
    //   };
    // }
    // if (isEmpty(newPatient.country)) {
    //   tempErrors = {
    //     ...tempErrors,
    //     country: "Country cannot be empty",
    //   };
    // }
    setErrors({ ...tempErrors });
    return Object.keys(tempErrors).length === 0;
  }

  function handleNext() {
    console.log(activeStep);
    if (0 === activeStep) {
      if ("yes" === hasMCN) {
        if (validateMCN()) {
          ClinicDataService.searchPatient(clinicId, {
            mediCardNumber: mcn,
          }).then(
            (response) => {
              if (response.data.content.length !== 0) {
                setNewPatient(response.data.content[0]);
              } else {
                setNewPatient({ ...newPatient, mediCardNumber: mcn });
                //setNewPatient({});
              }
            },
            (error) => console.log(error)
          );
          //setNewPatient({ ...newPatient, mediCardNumber: mcn });
        } else {
          return;
        }
      }
    }
    if (1 === activeStep) {
      if (0 !== selectedPatientId) {
        setNewPatient({
          ...candidateList.find(
            (element) => element.patientId === selectedPatientId
          ),
        });
      } else {
        if (!validatePatient()) {
          return;
        }
      }
    }
    if (activeStep === 3) {
      if (selectedDoctorId !== 0) {
        if (newPatient.patientId) {
          PatientDataService.updatePatient({
            ...newPatient,
            id: newPatient.patientId,
            clinicId: clinicId,
          }).then(
            (response) => {
              assignPatientToDoctor(newPatient.patientId);
            },
            (error) => {
              displayAlert(error.response.data.message);
              setActiveStep(3);
              return;
            }
          );
        } else {
          PatientDataService.createPatient({
            ...newPatient,
            clinicId: clinicId,
          }).then(
            (response) => {
              assignPatientToDoctor(response.data.patientId);
              handleUploadPhoto(response.data.patientId);
            },
            (error) => {
              displayAlert(error.response.data.message);
              setActiveStep(3);
              return;
            }
          );
        }
      }
    }
    setActiveStep(activeStep + 1);
  }

  function handlePhotoChange(event) {
    setPhoto(event.target.files[0]);
  }

  function handleUploadPhoto(patientId) {
    const data = new FormData();
    data.append("file", photo);
    PatientDataService.uploadPhoto(patientId, data).then(
      (response) => {
        console.log(response);
      },
      (error) => console.log(error)
    );
  }

  function assignPatientToDoctor(patientId) {
    PatientDataService.assignPatientToDoc(patientId, selectedDoctorId).then(
      (response) => {
        displayMessage(
          "The new patient has been successfully created and link to the doctor."
        );
        setTimeout(() => {
          props.populateClinicPatients(0, 10);
        }, 2000);
        console.log(response.data);
        props.closeNewPat();
      },
      (error) => console.log(error)
    );
  }

  function searchPatient() {
    ClinicDataService.searchPatient(clinicId, newPatient).then(
      (response) => {
        setCandidateList(response.data.content);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function handleDateChangeExp(date) {
    setDateExp(date);
    setNewPatient({
      ...newPatient,
      mediExp: moment(date).format("YYYY-MM-DD"),
    });
  }

  function handleDateChangeBd(date) {
    setDateBd(date);
    setNewPatient({
      ...newPatient,
      dateOfBirth: moment(date).format("YYYY-MM-DD"),
    });
  }

  function changePatientGender(event) {
    setPatientGender(event.target.value);
    setNewPatient({ ...newPatient, gender: event.target.value });
  }

  function resetPage() {
    setNewPatient({
      ...newPatient,
      firstName: "",
      lastName: "",
      gender: "",
      contactPhone: "",
      contactPhoneExt: "",
      contactPhoneType: "",
      email: "",
      postcode: "",
      streetNumber: "",
      streetName: "",
      city: "",
      country: "",
      mediExp: "",
      dateOfBirth: "",
    });
    setPatientGender("");
    setDateExp(null);
    setDateBd(null);
  }

  return (
    <React.Fragment>
      <Typography align="right">
        <IconButton color="primary" onClick={props.closeNewPat}>
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </Typography>
      <Stepper
        activeStep={activeStep}
        style={{ backgroundColor: "transparent" }}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                classes={{
                  iconContainer: classes.iconContainer,
                  label: classes.step_label_root,
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Paper>
        <form>
          <Container
            style={{
              display: activeStep === 0 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className={classes.tabTitle}>
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider />
            <Typography
              required
              style={{ marginTop: 50, marginLeft: 10 }}
              className={classes.inputTitle}
            >
              Do you have medical card?
            </Typography>
            <RadioGroup
              row
              style={{ marginLeft: 20 }}
              value={hasMCN}
              onChange={changeHasMCN}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                classes={{ label: classes.checkBoxLabel }}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                classes={{ label: classes.checkBoxLabel }}
                label="No"
              />
            </RadioGroup>
            <FormControl
              className={classes.formControl}
              style={{ marginTop: 30 }}
            >
              <Typography className={classes.inputTitle}>
                Medical Card Number
              </Typography>
              <OutlinedInput
                error={errors.mcn ? true : false}
                id="searchBox"
                name="searchBox"
                className={classes.textField}
                value={mcn}
                disabled={hasMCN === "no"}
                onChange={changeMcn}
              />
            </FormControl>
            {errors.mcn && (
              <Typography variant="subtitle1" style={{ color: "red" }}>
                {errors.mcn}
              </Typography>
            )}
          </Container>
          <Container
            style={{
              display: activeStep === 1 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className={classes.tabTitle}>
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 50 }}>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Medical card number
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.mediCardNumber}
                    onChange={changePatientInfo("mediCardNumber")}
                    disabled
                    error={errors.mediCardNumber ? true : false}
                    helperText={errors.mediCardNumber}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Expiration{" "}
                    <span style={{ color: "gray", fontWeight: "normal" }}>
                      (YYYY-MM-DD)
                    </span>
                  </FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className="group-item"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      minDate={new Date()}
                      value={dateExp}
                      onChange={handleDateChangeExp}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disabled={hasMCN === "no"}
                      error={errors.mediExp ? true : false}
                      helperText={errors.mediExp}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    First name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.firstName}
                    onChange={changePatientInfo("firstName")}
                    error={errors.firstName ? true : false}
                    helperText={errors.firstName}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Last name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.lastName}
                    onChange={changePatientInfo("lastName")}
                    error={errors.lastName}
                    helperText={errors.lastName}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Gender</FormLabel>
                  <RadioGroup
                    row
                    value={patientGender}
                    onChange={changePatientGender}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio color="primary" />}
                      label="Don't want to tell"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                {/* <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Phone</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.contactPhone}
                    onChange={changePatientInfo("contactPhone")}
                  />
                </FormControl> */}
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Phone</FormLabel>
                  {newPatient.contactPhoneType === "CELL" ? (
                    <Grid item md={12}>
                      <Grid container>
                        <Grid item md={2}>
                          <Typography variant="subtitle1">
                            Mobile Phone
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <TextField
                            value={newPatient.contactPhone}
                            onChange={changePatientInfo("contactPhone")}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={newPatient.contactPhoneType === "CELL"}
                                onChange={changePhoneType("LAND")}
                                name="mobilephone"
                                color="primary"
                              />
                            }
                            label="Mobile"
                          />
                        </Grid>
                        <Grid item md={4}></Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item md={12}>
                      <Grid container>
                        <Grid item md={2}>
                          <Typography variant="subtitle1">Landline</Typography>
                        </Grid>
                        <Grid item md={4}>
                          <TextField
                            value={newPatient.contactPhone}
                            onChange={changePatientInfo("contactPhone")}
                            style={{ marginRight: 30 }}
                          />
                        </Grid>
                        <Grid item md={1}>
                          <Typography variant="subtitle11">Ext</Typography>
                        </Grid>
                        <Grid item md={2}>
                          <TextField
                            value={newPatient.contactPhoneExt}
                            onChange={changePatientInfo("contactPhoneExt")}
                            style={{ marginRight: 30 }}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={newPatient.contactPhoneType === "CELL"}
                                onChange={changePhoneType("CELL")}
                                name="Mobile"
                                color="primary"
                              />
                            }
                            label="Mobile"
                          />
                        </Grid>
                        <Grid item md={1}></Grid>
                      </Grid>
                    </Grid>
                  )}
                  {errors.contactPhone && (
                    <Typography variant="subtitle2" style={{ color: "red" }}>
                      {errors.contactPhone}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Date of birth{" "}
                    <span style={{ color: "gray", fontWeight: "normal" }}>
                      (YYYY-MM-DD)
                    </span>
                  </FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className="group-item"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      useCurrent={false}
                      maxDate={new Date()}
                      value={dateBd}
                      onChange={handleDateChangeBd}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      error={errors.dateOfBirth ? true : false}
                      helperText={errors.dateOfBirth}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Email</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.email}
                    onChange={changePatientInfo("email")}
                    error={errors.email ? true : false}
                    helperText={errors.email}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    PostalCode / Zip{" "}
                    <span style={{ color: "gray", fontWeight: "normal" }}>
                      (H1H 1H1)
                    </span>
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.postcode}
                    onChange={changePatientInfo("postcode")}
                    error={errors.postcode ? true : false}
                    helperText={errors.postcode}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Street Number
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.streetNumber}
                    onChange={changePatientInfo("streetNumber")}
                    error={errors.streetNumber ? true : false}
                    helperText={errors.streetNumber}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Street Name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.streetName}
                    onChange={changePatientInfo("streetName")}
                    error={errors.streetName ? true : false}
                    helperText={errors.streetName}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>City</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.city}
                    onChange={changePatientInfo("city")}
                    error={errors.city ? true : false}
                    helperText={errors.city}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Country</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    value={newPatient.country}
                    onChange={changePatientInfo("country")}
                    error={errors.country ? true : false}
                    helperText={errors.country}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Container style={{ display: "flex", justifyContent: "right" }}>
              <Button
                variant="contained"
                onClick={searchPatient}
                disabled={newPatient.mediCardNumber}
                style={{
                  marginTop: 30,
                  color: "#000000",
                  backgroundColor: "#f0f8ff",
                  width: "86px",
                  height: "30px",
                  display: newPatient.mediCardNumber ? "none" : "block",
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                onClick={resetPage}
                style={{
                  marginTop: 30,
                  marginLeft: 20,
                  color: "#000000",
                  backgroundColor: "#fff8dc",
                  width: "86px",
                  height: "30px",
                }}
              >
                Reset
              </Button>
            </Container>
            <Divider
              style={{ marginTop: 60, marginRight: 10, marginBottom: 50 }}
            />
            <Container>
              <RadioGroup value={selectedPatientId} onChange={selectPatientId}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>MCN</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Date of birth</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidateList.map((element) => (
                      <TableRow key={element.patientiId}>
                        <TableCell>
                          <FormControlLabel
                            value={element.patientId}
                            control={<Radio />}
                            label={element.patientId}
                          />
                        </TableCell>
                        <TableCell>
                          {element.firstName +
                            " " +
                            element.middleName +
                            " " +
                            element.lastName}
                        </TableCell>
                        <TableCell>{element.mediCardNumber}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{element.dateOfBirth}</TableCell>
                        <TableCell>{element.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </RadioGroup>
            </Container>
          </Container>
          <Container
            style={{
              display: activeStep === 2 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className={classes.tabTitle}>
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 50 }}>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Medical card number
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newPatient.mediCardNumber}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Expiration
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newPatient.mediExp}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    First name
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newPatient.firstName}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Last name
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newPatient.lastName}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Phone</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newPatient.contactPhone}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>
                    Date of birth
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newPatient.dateOfBirth}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Email</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>{newPatient.email}</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel className={classes.inputTitle}>Address</FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <FormLabel>
                    {newPatient.streetNumber} {newPatient.streetName},{" "}
                    {newPatient.city}, {newPatient.country},{" "}
                    {newPatient.postcode}
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={3}></Grid>
            </Grid>
          </Container>
          <Container
            style={{
              display: activeStep === 3 ? "block" : "none",
              minHeight: 400,
            }}
          >
            <Typography variant="h5" className={classes.tabTitle}>
              {activeStep + 1}. {steps[activeStep]}
            </Typography>
            <Divider />
            <Grid container>
              <Grid item md={4}>
                <Typography
                  style={{ fontSize: 16, fontWeight: "bold", color: "#3553B2" }}
                >
                  PHOTO
                </Typography>
                <Avatar
                  style={{
                    minWidth: 120,
                    minHeight: 120,
                    margin: "24px auto 48px",
                  }}
                />
                <FormControl
                  style={{
                    margin: "50px auto 0",
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  <TextField type="file" onChange={handlePhotoChange} />
                </FormControl>
              </Grid>
              <Grid item md={8}>
                <Typography variant="h6" style={{ marginTop: 40 }}>
                  Assign to Doctor
                </Typography>
                <Card>
                  <CardContent>
                    <RadioGroup
                      value={selectedDoctorId}
                      onChange={selectDoctorId}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Category</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.doctorList.map((doctor) => (
                            <TableRow key={doctor.id}>
                              <TableCell>
                                <FormControlLabel
                                  value={doctor.id}
                                  control={<Radio />}
                                  label={doctor.id}
                                />
                              </TableCell>
                              <TableCell>
                                Dr.{" "}
                                {doctor.firstName +
                                  " " +
                                  doctor.middleName +
                                  " " +
                                  doctor.lastName}
                              </TableCell>
                              <TableCell>
                                {doctor.categoryDescriptions.map(
                                  (description, index) =>
                                    "1" === description.isLeaf
                                      ? index !== 0
                                        ? " / " +
                                          JSON.parse(description.description).en
                                        : JSON.parse(description.description).en
                                      : ""
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </RadioGroup>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={handleBack}
              className={classes.button}
              disabled={activeStep <= 0 ? true : false}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
            >
              {activeStep === 3 ? "Save" : "Next"}
            </Button>
          </Container>
        </form>
      </Paper>
    </React.Fragment>
  );
}

export default NewPatient;
